<!-- 建材贸易 -->
<template>
  <div class="mt-80-mb-140">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-12">
          <div class="pic">
            <img
              :src="content.pic"
              class="img-fluid d-block mx-auto"
              style="margin-bottom: 30px"
            />
          </div>
          <div class="content" v-html="content.content"></div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-12 col-xl-6 col-xxl-6"
          v-for="(img, index) in content.images"
          :key="img"
          :class="{ 'mx-auto': content.images.length === 1 }"
        >
          <div
            class="bottom-img-box"
            :class="{
              'float-start': index === 1,
              'float-end': index === 0,
              'float-none': content.images.length === 1
            }"
          >
            <img :src="img" class="d-block" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="container">
      <div class="row">
        <div class="col-12 col-xl-12 company">
          <div class="title text-center">经营该业务的主要公司</div>
          <div
            class="company-item"
            v-for="(item, index) in company"
            :key="index"
          >
            <div class="company-name">{{ item.name }}</div>
            <div class="people-number">{{ item.people }}</div>
            <div class="contact">
              <a :href="`tel:${item.tel}`" class="contact-span"
                ><span class="fw-bold">电话：</span> {{ item.tel }}</a
              >
              <span class="contact-span"
                ><span class="fw-bold">邮箱：</span>{{ item.email }}</span
              >
            </div>
            <a :href="item.url" class="link" target="_blank"
              >访问官网<i class="bi bi-arrow-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { getBuilding } from '../../../server/business'
// const company = ref([])
const content = ref('')

onMounted(async () => {
  const r = await getBuilding()
  // company.value = r.company
  content.value = r
})
</script>
<style lang="stylus" scoped>
.content
  overflow hidden
  .img
    max-width 100%
.company
  margin-top 50px
  @media screen and (max-width 768px){
    margin-top 30px
  }
  .title
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    @media screen and (max-width 768px){
      font-size 30px
    }
  .company-item
    padding 35px 0
    border-bottom 1px dotted #D5D5D5
    position relative
    @media screen and (max-width 768px){
      padding 15px 0
    }
    .company-name
      font-size: 26px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 1.2
      margin-bottom 20px
      @media screen and (max-width 768px){
        font-size 20px
      }
    .people-number
      color #999
      font-size: 18px
      line-height 1.2
    .contact
      font-size 18px
      color #333333
      .contact-span
        margin-right 40px
        color #333333
        text-decoration none
        @media screen and (max-width 768px){
          display block
        }
    .link
      width: 160px;
      display block
      text-align center
      line-height 60px
      text-decoration none
      height: 60px;
      color #fff
      background: #209BD7;
      box-shadow: 0px 10px 9px 1px rgba(207, 207, 207, 0.5);
      border-radius: 5px;
      position absolute
      right 20px
      top 50%
      transform translateY(-50%)
      transition all .3s
      &:hover
        background rgba(16, 54, 107, 1)
      @media screen and (max-width 768px){
        position static
        margin-top 20px
        transform translateY(0)
        width 100px
        height 30px
        line-height 30px
      }
.bottom-img-box
  border 5px solid #fff
  width 550px
  height 360px
  overflow hidden
  max-width 100%
  @media screen and (max-width 768px) {
    margin-bottom 10px
  }
  img
    width 100%
    height 100%
    object-fit contain
</style>
